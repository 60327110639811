import { useApi } from '@/hooks/use-api';
import api from '@/utilities/api';

import CameraIcon from '@mui/icons-material/Camera';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ConstructionIcon from '@mui/icons-material/Construction';
import RestaurantIcon from '@mui/icons-material/Restaurant';

import BlueprintsListView from '@/components/BlueprintsList/BlueprintsListView';

const defaultProps = {};

type BlueprintsListContainerProps = {
  location: Location;
  organizationId: string | number;
} & typeof defaultProps;

export function BlueprintsListContainer({
  location,
  organizationId,
}: BlueprintsListContainerProps) {
  const { data, error, loading } = useApi(
    api.blueprints.list.bind(api.blueprints),
    [organizationId],
    {},
  );
  const queryParams = new URLSearchParams(location.search);
  const categories = [
    {
      id: `RESTAURANT`,
      label: `Restaurant`,
      icon: <RestaurantIcon />,
    },
    {
      id: `PHOTOGRAPHY`,
      label: `Photography`,
      icon: <CameraIcon />,
    },
    {
      id: `UNDER_CONSTRUCTION`,
      label: `Under construction`,
      icon: <ConstructionIcon />,
    },
    {
      id: `CONTACT_PAGE`,
      label: `Contact page`,
      icon: <ContactPageIcon />,
    },
  ];
  return (
    <BlueprintsListView
      blueprints={data?.blueprints}
      categories={categories}
      error={error}
      loading={loading}
      organizationId={organizationId}
      queryParams={queryParams}
    />
  );
}

BlueprintsListContainer.defaultProps = defaultProps;

export default BlueprintsListContainer;

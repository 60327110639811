import { withAuthenticationRequired } from '@auth0/auth0-react';

import AppFrame from '@/components/AppFrame';
import BlueprintsList from '@/components/BlueprintsList';

export function BlueprintsIndex({
  location,
  organizationId,
}: {
  location: Location;
  organizationId: string;
}) {
  return (
    <AppFrame organizationId={organizationId}>
      <BlueprintsList organizationId={organizationId} location={location} />
    </AppFrame>
  );
}

export default withAuthenticationRequired(BlueprintsIndex);

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import EastIcon from '@mui/icons-material/East';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PageError from '@/components/core/PageError';
import PageLoading from '@/components/core/PageLoading';
import UndrawLandingPage from '@/assets/svgs/undraw-landing-page.svg';

// eslint-disable-next-line import/no-extraneous-dependencies
import { LandingBlueprint20211012Blueprint } from '@clients/landing-blueprint';

const defaultProps = {};

type Category = {
  id: string;
  icon: ReactNode;
  label: string;
};

type BlueprintsListViewProps = {
  blueprints: Array<LandingBlueprint20211012Blueprint>;
  categories: Array<Category>;
  error: any;
  loading: boolean;
  organizationId: string | number;
  queryParams: URLSearchParams;
} & typeof defaultProps;

export function BlueprintsListView({
  blueprints,
  categories,
  error,
  loading,
  organizationId,
  queryParams,
}: BlueprintsListViewProps) {
  const { t } = useTranslation();
  return (
    <Box>
      <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}`}
            underline="hover"
          >
            {organizationId}
          </Link>
          <Typography color="text.primary">
            {t(`blueprints.blueprints`)}
          </Typography>
        </Breadcrumbs>
        <Stack
          direction={{ xs: `row`, sm: `row` }}
          justifyContent="space-between"
        >
          <Typography
            variant="h1"
            color="inherit"
            sx={{ mb: { xs: 1, sm: 0 } }}
          >
            {t(`blueprints.blueprints`)}
          </Typography>
        </Stack>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={12}>
            {false && (
              <Toolbar sx={{ p: { sm: 0 } }}>
                {loading ? (
                  <>
                    <Skeleton
                      height={60}
                      sx={{ fontSize: `1rem`, mr: 0.5 }}
                      variant="text"
                      width={120}
                    />
                    <Skeleton
                      height={60}
                      sx={{ fontSize: `1rem`, mr: 0.5 }}
                      variant="text"
                      width={120}
                    />
                    <Skeleton
                      height={60}
                      sx={{ fontSize: `1rem`, mr: 0.5 }}
                      variant="text"
                      width={120}
                    />
                    <Skeleton
                      height={60}
                      sx={{ fontSize: `1rem`, mr: 0.5 }}
                      variant="text"
                      width={120}
                    />
                    <Skeleton
                      height={60}
                      sx={{ fontSize: `1rem` }}
                      variant="text"
                      width={120}
                    />
                  </>
                ) : error ? (
                  <Box>{t(`blueprints.categories.error`)}</Box>
                ) : categories && categories.length ? (
                  categories.map((category) => (
                    <Button
                      component={GatsbyLink}
                      disableRipple
                      key={category.id}
                      startIcon={category.icon}
                      size="medium"
                      to={`/organizations/${organizationId}/blueprints?category=${category.id}`}
                      variant={
                        queryParams?.get(`category`) === category.id
                          ? `contained`
                          : `text`
                      }
                    >
                      {category.label}
                    </Button>
                  ))
                ) : (
                  <Box>{t(`blueprints.categories.zero-state.message`)}</Box>
                )}
              </Toolbar>
            )}
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={4}>
              {loading ? (
                <PageLoading>{t(`blueprints.loading`)}</PageLoading>
              ) : error ? (
                <PageError>{error.message || t(`blueprints.error`)}</PageError>
              ) : blueprints && blueprints.length ? (
                blueprints.map((blueprint) => (
                  <Grid item key={blueprint?.id} xs={4}>
                    <Card raised={false} variant={`outlined`}>
                      <CardHeader
                        title={blueprint?.name}
                        subheader={blueprint?.description}
                      />
                      <CardActions>
                        <Button
                          color="secondary"
                          component={GatsbyLink}
                          endIcon={<EastIcon />}
                          size="medium"
                          to={`/organizations/${organizationId}/sites/create?blueprint_id=${blueprint.id}`}
                        >
                          {t(`blueprints.deploy`)}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Box
                  sx={{
                    maxWidth: 640,
                    my: 7,
                    mx: `auto`,
                    textAlign: `center`,
                  }}
                >
                  <UndrawLandingPage height="200" width="200" />
                  <Typography variant="h5">
                    {t(`blueprints.zero-state.no-blueprints`)}
                  </Typography>
                  <Typography
                    sx={{ color: `text.secondary`, mb: 2 }}
                    variant="body1"
                  >
                    {t(`blueprints.zero-state.add-one-now`)}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

BlueprintsListView.defaultProps = defaultProps;

export default BlueprintsListView;
